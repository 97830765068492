.hero-img{
    width: 100%;
    background: rgba( 0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
}

.hero-img::before{
    content: "";
    background: url(https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80);
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:-1;
}

.heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}



.hero-img h1{

    font-size: 2.4rem;
}

.hero-img p{
    font-size: 1.45rem;
    padding: 1rem;
    text-align: center;
}

@media  screen and (max-width: 640px) {
    .hero-img h1{
        padding-top: 1rem;
        font-size: 2rem;
    }
    .hero-img p{
        font-size: 1.5rem;
    }
    
    
}