@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900& display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.footer{
    width: 100%;
    padding: 1rem 0;
    background-color: black;
}

.footer-container{
    max-width: 1140px;
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
}

.menu{
    margin-left: 250px;
    margin-top: 30px;
}

h4{
    text-align: center;
}


.icons{
    display: flex;
    margin-left: 50px;
    justify-content:space-around;
    margin-top:50px;
    text-align: center;
    align-items: center;
    align-self: center;
    align-content: center;
}


p{
    font-size: 2.6rem;
}

.btn3{
    height: fit-content;
    font-size: 1.5rem;
    margin-left: 15px;
    margin-right: 15px;
    padding:8px;
    text-transform: uppercase;
    text-align: center;
    color:white;
    border: 1px solid #fff;
    outline: none;
    font-weight: 600; 
    cursor: pointer;
    background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
   
}

.btn3:hover {
    background: Orgba(255, 255, 255, 0.2);
    color:black;
    transition: all .4s ease-in-out;
    background-position: 100% 0;
    background-size: 300% 100%;
    border-radius: 50px;
    cursor: pointer;
}

@media screen and (max-width: 740px){
    .footer-container{
        margin: auto;
        margin-bottom: 50px;
        text-align: center;
    }

    p{
        font-size: 1.3rem;
        padding-right:20px;
        padding-left: 20px;
    }

  

    .menu{
        margin-left: 50px;
    }
   
}