.about{
    width:1140px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    
}

.about .left{
    text-align: left;
    margin: auto;
    max-width: 600px;
    justify-content: center;

    padding: 10px;

    
}

.about .right{
    max-width: 500px;
    margin: auto;
    justify-content: center;
    padding-left: 40px;
    height: fit-content;
    padding: 10px;

  
}

.about .left p{
    margin: 1.2rem 0;
}

.about .right .image-container{
   
    justify-content:center;
    margin: auto;
   
}


.right img{
    max-width: 90%;
    border: 3px solid #fff;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    
}

.imgTop{
    padding-top: 40px;
    padding-bottom: 40px;
}

.imgBottom{
    padding-top: 40px;
    padding-bottom: 40px;
}



.buttons{
    margin-right: 75px;
    display: block;
    flex: 1;
    flex-direction:column;
    justify-content:center;
    width:450px;
    margin: auto;
    padding-top: 100px;
}

.right button {
    margin: 0.5rem;
    padding: 0.5rem;
    justify-content: space-between;
    width: 400px;
    justify-content:center;
}



@media screen and (max-width: 740px){
    .buttons{
       
        margin: auto;
        width: 400px;
        justify-content:center;
        margin-top: 50px;
        text-align: center;
        
    }

    .right button {
        
        margin: 0.5rem;
        padding: 0.5rem;
        width: 350px;
        justify-content:center;
        margin-top: 20px;
  
    }

    .about{
        width:400px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 100%;
        margin: auto;
        padding-top: 70px;
      
        
    }
    
    .about .left{
        text-align: left;
        margin: auto;
        width: auto;
        justify-content: center;
      
        
    }
    
    .about .right{
        width: auto;
        margin: auto;
        justify-content: center;
        padding-left: 0px;
        padding: 0;;
        margin: 0;
    }

 

    .imgTop{
        padding: 0;
        padding-top: 50px;
        padding-bottom: 50px;
        margin: 0;
        justify-content: center;
        max-width: 95%;
        margin-left: 25px;
    }
    
    .imgBottom{
        padding: 0;
        padding-top: 50px;
        padding-bottom: 50px;
        margin: 0;
        justify-content: center;
        max-width: 95%;
        margin-left: 25px;
    }

    .image-container{
        justify-content: center;
        margin: auto;
        align-self: center;
        align-items: center;
        width: 400px;
        margin: 0;
        padding: 0;
        display: block;
     
    }

    .buttons{
        padding-top: 0px;
    }
    

   
    
   
}