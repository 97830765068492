label{
    color: #fff;

}

.containForm{
    width: 1200px;
    margin: auto;
    padding:auto;
    height: 550px;
}

form{
  
    justify-content: center;
    margin: auto;
    max-width: 600px;
    padding: 20px;
    align-self: center;
    margin-top: 100px;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
}

form label{

    color: #fff;
   
    background-color: black;
    max-width: fit-content;
    font-size: 25px;
   
    height: fit-content;

    
}

form input{
    padding:10px;
    border: none;
    border-radius: 4px;
    outline: 2px solid #fff;
    background-color: transparent;
    color: white;
    transition: outline-color 500ms;
    width: 560px;

   
}

form textarea{
    color:#fff;
    padding:10px;
    border: none;
    border-radius: 4px;
    outline: 2px solid #fff;
    background-color: transparent;
    transition: outline-color 500ms;
    width: 560px;
    margin-bottom: 20px;

}

form textarea:focus, :valid{
    outline-color: #30dd8a;
    
}

form input:focus, :valid{
    outline-color:  #30dd8a;
}


form textarea:hover{
    outline-color: #25aae1;
    
}

form input:hover{
    outline-color:  #25aae1;
}

@media screen and (max-width: 740px){
   

    .containForm{
        width: 400px;
        margin: auto;
        padding:auto;
        height: 550px;
        margin-top: 100px;
        
    }
    
    form{
        margin-top: 100px;
        justify-content: center;
        margin: auto;
        max-width: 350px;
        padding: 20px;
        align-self: center;
        border-color: #26bf47;
        box-shadow: 0 0 20px #26bf47;
    }
    
    form label{
        top:0;
        left:0;
        translate: 10px 10px;
        color: #fff;
        transition: translate 500ms,scale 500ms;
        background-color: black;
        max-width: fit-content;
        font-size: 25px;
    }
    
    form input{
        color:#fff;
        padding:10px;
        border: none;
        border-radius: 4px;
        outline: 2px solid #fff;
        background-color: transparent;
        transition: outline-color 500ms;
        width: 300px;
       
    }
    
    form textarea{
        color:#fff;
        padding:10px;
        border: none;
        border-radius: 4px;
        outline: 2px solid #fff;
        background-color: transparent;
        transition: outline-color 500ms;
        width: 300px;
        margin-bottom: 50px;
    
    }
}