@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900& display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.timeline{
    padding: 0 !important;

}

.timeline .timeline_header{
   
    color: #eee;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    padding-top: 15px;
}

.timeline .timeline_firstItem{
    min-height: 120px !important;

}

.timeline .MuiTimelineItem-root{
    min-height: 0px;

}

.timeline .MuiTimelineItem-missingOppositeContent:before{
    display: none;
}

.timeline .timeline_dot_header{
    color: white !important;
    background-color: green !important;
    font-size: large !important;
    padding: 2px !important;
    height: 80px;
    width: 80px;
   
}

.timeline .timeline_dot {
    color: white !important;
    background-color: green !important;
    padding: 2px !important;
    height: 20px;
    width: 20px;
    
    
}


.timeline .MuiTimelineItemConnector-root{
    background-color: #eee !important;

}

.timeline .timeline_dot_header > .MuiSvgIcon-root{
    font-size: 70px;
    color: white;
   
}



.timeline .seperator_padding{
    padding-left: 30px !important;
 

}