@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900& display=swap');


.sectionAbout{
    font-size: 1.5rem;
    color: white;
    padding: 5px;
    font-family: Monteserrat;
}


.sectionAbout2{
 
    font-size: 1rem !important;
    color: white;
    padding: 10px;
    font-family: Monteserrat;
}

.resume_timeline .timeline_content .timeline_title{
    font-size: 16px !important;
    
    font-family: Monteserrat;

  
}

.resume_timeline .timeline_content .timeline_data{
    color: white;
}


.section_title_text{
    color: white;
    padding-top: 5px;
    font-size: 4rem;
    padding-bottom: 10px;
    

 
}

.section_title_text2{
    padding-top: 5px;
    color: white;
    font-size: 1.6rem;
    padding-left: 10px;
    padding-bottom: 15px;


}

.section_title_text2 h3{
    font-family: Monteserrat;
    font-size: 18px;
    padding: 5px;
    
}


.timeline_date{
    color: white;
    font-family: Monteserrat;

}
.resume_timeline .timeline_content .timeline_description{
    color: white;
    margin-top: 6px;
}

.resume_timeline .timeline_content{
    border-color: red;
}

.skillSection{
    width: fit-content;
    height: fit-content;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    padding: 10px
}

.accordion{
color: white;
}

.accordion-title{
color: white;
}

.accordion-item{
color: white;
}


.accordion-content{
color: white;
}

.view{
    font-size: 1.5rem;
    color: white;
    padding: 5px;
    font-family: Monteserrat;
}

.view:hover{
    cursor: pointer;
    color: #26bf47;
}

@media screen and (max-width: 740px){

.section_title_text2 li{
    font-family: Monteserrat;
    font-size: 18px;
    width: 350px;
}

}