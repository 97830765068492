@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900& display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Bebas Neue', sans-serif;
}
body{
background: black;
}

h1, h4, 
p,
a{
  color: #fff;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn {
  padding: 12px 32px; 
  font-size: 1.5rem;
  text-transform: uppercase;
  color:white;
  border: 1px solid #fff;
  outline: none;
  font-weight: 600; 
  cursor: pointer;
  background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  }
  
  
  .btn-light {
  background: transparent;
  color: #fff;
  }
  
  .btn:hover {
  background: Orgba(255, 255, 255, 0.2);
  color:black;
  transition: all .4s ease-in-out;
  background-position: 100% 0;
  background-size: 300% 100%;
  border-radius: 50px;
  cursor: pointer;

  }

  
.btn2 {
  font-size: 1.5rem;
  margin-left: 15px;
  margin-right: 15px;
  padding:8px;
  text-transform: uppercase;
  text-align: center;
  color:white;
  border: 1px solid #fff;
  outline: none;
  font-weight: 600; 
  cursor: pointer;
  background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  
  }


  
  
  .btn2-light {
  background: transparent;
  color: #fff;
  }
  
  .btn2:hover {
  background: Orgba(255, 255, 255, 0.2);
  color:black;
  transition: all .4s ease-in-out;
  background-position: 100% 0;
  background-size: 300% 100%;
  border-radius: 50px;
  cursor: pointer;

  }

  













