.mask{
    width: 100%;
    height: 100vh;
    position: relative;
}

.into-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    top: 0;
    left: 0;
    opacity: 0.6;

}

.hero{
    height: 100%;
    width: 100%;
}

.hero .content{
position: absolute;
transform: translate(-50%,-50%);
top: 50%;
left: 50%;
text-align: center;
}

.hero .content h1{
    font-size: 6rem;
}

.hero .content p{
    font-size: 2.2rem;
    font-weight: 200;
    padding-top: 0rem;
    padding-bottom: 1.5rem;
}

.content .btn{
    
    margin: 0.2rem;
    justify-content: center;
    
}

.circle{
    border-radius: 50%;
    width: 200px;
    height: 200px;
    border: solid white;
}

.threeRow{
    grid-template-columns: 0 0 0;
    justify-content: center;
    margin: auto;
    padding: 5px;

    width: fit-content;
    height: fit-content;
    display: flex;

}
.button1{
justify-content: center;

margin: auto;
}

.button2{
    justify-content: center;
   
    margin: auto;
}

@media screen and (max-width: 640px) {
    .hero .content h1{
        font-size: 3rem;
        width: 350px;
    }


.circle{
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: solid white;
}

    
    .hero .content p{
        font-size: 1.25rem;
    }

    .threeRow{
        grid-template-columns: 0 0 0;
        justify-content: center;
        margin: auto;
        padding: 5px;
        width: 300px;
        height: fit-content;
        display: flex;
    
    }
    .button1{
    justify-content: center;
 
    margin: auto;
    }
    
    .button2{
        justify-content: center;

        margin: auto;
    }
}