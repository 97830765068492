
.work-container{
    max-width: 1140px;
    margin: auto;
    justify-content: space-between;
    padding-bottom: 5rem;
}

.project-heading{
    text-align: center;
   
    font-size: 1.5rem;
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
}




.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.project-card{
    background: black;
    padding: 1.2rem 1rem;
    border: 1px solid #fff;
    align-items: center;
    outline: none;
    border-color: #26bf47;
    box-shadow: 0 0 10px #26bf47;
    align-items: center;
    justify-content: center;
 
    width: 340px;
    height: fit-content;
    margin-left: 30px;
    box-shadow: 0 35px 80px rgba(0,0,0,0.15);
	transition: 0.5s;
    overflow: hidden;
}

.project-card:hover{
    background: transparent;
    color: #fff;
    background: Orgba(255, 255, 255, 0.2);
   
    transition: all .4s ease-in-out;
    background-position: 100% 0;
    background-size: 300% 100%;
    border-radius: 50px;
    cursor: pointer;
    height: fit-content;
}

.project-card img{
    width: 100%;
    position: absolute;
	top: 20px;
	width: 300px;
	height: 220px;
	background: #333;
	border-radius: 12px;
	overflow: hidden;
	transition: 0.5s;

}


.project-card:hover img{
    top: -100px;
	scale: 0.75;
	box-shadow: 0 15px 45px rgba(0,0,0,0.2);
	
	padding: 3px;
}

.project-title{
    color: #fff;
    text-align: center;
    margin-top:225px;
    font-size: 1.9rem;

}

.project-card:hover .project-title{
    margin-top: 100px;
}


.pro-details p{
 
    font-size: 1.1rem;
    margin-top: 10px;
    display: none;
    font-family: Monteserrat;

}


.project-card:hover .pro-details p{
  margin-top: 10px;
  display:block;
}


.pro-btns {
    justify-content: center;
    align-items: center;
    padding-left: 3.2rem;
    margin-top: 10px;
}

.project-card:hover .pro-btns{
  margin-top: 40px;
}

.projectShowcase{
    width: 1140px;
    height: fit-content;
    margin: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

}

.projectShowcase h1{
    font-size: 40px;
}



.project1{
    height: fit-content;
    width: 900px;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    align-self: flex-end; 
    margin: 25px;
    text-align: right;
    padding: 20px;
}

.project2{
    height: fit-content;
    width: 900px;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    margin: 25px;
    padding: 20px;
    text-align: left;
    align-self: flex-start; 
    
}


.project3{
    height: fit-content;
    width: 900px;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    align-self: flex-end; 
    margin: 25px;
    padding: 20px;
    text-align: right;
}

.project1:hover,
.project2:hover,
.project3:hover{
        background: transparent;
        color: #fff;
        background: Orgba(255, 255, 255, 0.2);
       
        transition: all .4s ease-in-out;
        background-position: 100% 0;
        background-size: 300% 100%;
        border-radius: 50px;
        cursor: pointer;
        height: fit-content;
}

.project1 p,
.project3 p,
.project2 p{
    font-size: 22px;
    font-family: Monteserrat;
}

.projectImage{
    display: flex;
}

.projectImage img{
    width: 100%;
	width: 300px;
    height: 200px;

	border-radius: 12px;
    margin: auto;
    justify-content: center;
	transition: 0.5s;
    padding: 10px;
    transition: 0.5s;

  
    border-radius: 50px;

}


@media screen and (max-width: 740px){
    .project-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }

    .project-heading{
        font-size: 1.2rem;
        padding-left: 30px;
        padding-right: 30px;
    }

    .pro-btns {
        justify-content: center;
        align-items: center;
        padding-left: 2.7rem;
        margin-top: 10px;
    }

    .work-container{

        justify-content: space-between;
        padding-bottom: 5rem;
        width: 400px;
        
    }

    .project-card{
        background: black;
        padding: 1.2rem 1rem;
        border: 1px solid #fff;
        align-items: center;
        outline: none;
        border-color: #26bf47;
        box-shadow: 0 0 10px #26bf47;
        align-items: center;
        justify-content: center;
        margin: 10px;
        width: 340px;
        height: fit-content;
        margin-left: 30px;
        overflow: hidden;
     
    }

    .project-card:hover{
        height: fit-content;
    }

    .slideContain{
        width: 375px;
    }



.projectShowcase{
    width: 400px;
    height: fit-content;
    margin: auto;
    padding: 10px;
    
    justify-content: center;
    text-align: center;
    
}

.projectShowcase h1{
    font-size: 30px;
   
}





.project1{
    height: fit-content;
    width: 300px;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    justify-content: center;
    margin: auto;
    text-align: center;
    padding: 20px;
    margin-bottom: 50px;
    text-align: left;
}

.project2{
    height: fit-content;
    width: 300px;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    margin: auto;
    padding: 20px;
    text-align: left;
    justify-content: center;

    margin-bottom: 50px;
    
}


.project3{
    height: fit-content;
    width: 300px;
    border-color: #26bf47;
    box-shadow: 0 0 20px #26bf47;
    text-align: left;
    padding: 20px;
    text-align: center;
    justify-content: center;
    margin: auto;
    text-align: left;
 
}

.project1 p,
.project3 p,
.project2 p{
    font-size: 16px;
    font-family: Monteserrat;
    text-align: left;
    margin: 0;
    padding: 0;
   
}

.projectImage{
    display: block;
}

.projectImage img{
   all: initial;
   display: none;

}

.project1:hover,
.project2:hover,
.project3:hover{
        background: transparent;
        color: #fff;
        background: Orgba(255, 255, 255, 0.2);
       
        transition: all .4s ease-in-out;
        background-position: 100% 0;
        background-size: 0% 0%;
        border-radius: 50px;
        cursor: pointer;
        height: fit-content;
}

}

