
.menu{
  position:relative;
  width:280px;
  height:280px;
  display:flex;
  align-items:center;
  justify-content:center;
}
.menu .toggle{
  position:relative;
  height:120px;
  width:120px;
  background:black;
  border-radius:50%;
  box-shadow:0 3px 4px  #26bf47(0,0,0,0.15);
  display:flex;
  align-items:center;
  justify-content:center;
  color:#333;
  font-size:2rem;
  cursor:pointer;
  transition:1.25s;
  z-index:5;
  margin-top: 50px;
  margin-left: 100px;
  
  border-color: #26bf47;
  box-shadow: 0 0 100px #26bf47;
}
.menu.active .toggle{
  transform:rotate(360deg);
  box-shadow: 0 6px 8px rgba(0,0,0,0.15),
    0 0 0 2px #333,
    0 0 0 8px #26bf47,
    0 0 80px #26bf47;

}
.menu li{
  position:absolute;
  left:0;
  list-style:none;
  transition:0.5s;
  transform:rotate(calc(360deg/8 * var(--i)));
  transform-origin:160px;
  scale:0;
  transition-delay: calc(0.05s * var(--i));
}
.menu.active li{
  scale:1;
}
.menu li a{
  position:relative;
  display:flex;
 transform:rotate(calc(360deg/-8 * var(--i)));
  width:60px;
  height:60px;
  background-color:#000;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:50%;
  font-size:3rem;
  color:var(--clr);
   box-shadow:0 3px 4px rgba(0,0,0,0.15);
  transition:0.5s;
}
.menu li:hover a{
  font-size:2.5rem;
  box-shadow:0 0 0 2px var(--clr),
    0 0 0 6px #000;
}

@media screen and (max-width: 740px){

  .menu{
      margin-right: 100px;
      margin-top:0px;
  }

  .menu .toggle{
      position:relative;
      height:120px;
      width:120px;
      background:#000;
      border-radius:50%;
      box-shadow:0 3px 4px rgba(0,0,0,0.15);
      display:flex;
      align-items:center;
      justify-content:center;
      color:#333;
   
      cursor:pointer;
      transition:1.25s;
      z-index:5;
      border-color: #26bf47;
      box-shadow: 0 0 100px #26bf47;
      margin-left: 100px;
    }
}